.sidebar .pro-sidebar .pro-sidebar-inner {
    background-color: #f4f4f4;
}
.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-menu .pro-menu-item.active .pro-inner-item, .sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-menu .pro-menu-item.firstLevelMenu.open .pro-inner-item {
    background-color: rgba(0, 0, 0, 0.07);
}

.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-content .pro-menu .pro-menu-item .react-slidedown {
    margin-top: 5px;
    padding-left: 12px;
}
  
.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-content .pro-menu .pro-menu-item .pro-item-content {
    color: #000000;
    font-weight: 400;
    font-size: 13px;
}
  
.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-content .pro-menu .pro-menu-item.active .pro-item-content {
    font-weight: 800;
    font-size: 13px;
    color: #083a7b;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
    background-color: #fcfcfc;
    
} 
  
  
  