// Layout
.mainApp {
    .sidebarAside {
        position: relative;
        width: 280px;
        float: left;
        transition: width, left, right, 0.3s;

        .sidebar {
            position: fixed;
            left: 0;
            bottom: 0px;
            width: 280px;
            height: calc(100vh - 60px);
            color: #fff;
            transition: width, left, right, 0.3s;
            z-index: 1009;
        }
    }

    .mainPages {
        position: relative;
        width: calc(100% - 280px);
        transition: width, left, right, 0.3s;
        float: right;
    }

    &.collapsed {
        .sidebarAside {
            width: 80px;

            .sidebar {
                width: 80px;

                .sidebarCollapsed {
                    svg {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        .mainPages {
            width: calc(100% - 80px);
        }
    }
}

.hamburger {
    position: fixed;
    top: 15px;
    left: 10px;
    z-index: 1012;
    display: none;
    background-color: transparent;
    border: none;

    svg {
        width: 20px;
        height: auto;
    }
}

.icon-footer{
    margin-left: 25px;
}

// sidebar
.sidebar {
    .sidebarCollapsed {
        position: absolute;
        top: 50%;
        right: -15px;
        transform: translateY(-50%);
        width: 30px;
        height: 30px;
        background-color: #878787;
        border-radius: 50%;
        border: 1px solid rgba($color: #fff, $alpha: 0.5);
        z-index: 1010;
        display: grid;
        place-items: center;
        cursor: pointer;
        transition: transform 0.3s;

        svg {
            width: 13px;
            height: auto;
        }
    }

    .pro-sidebar {
        width: 100%;
        user-select: none;
        border: 1px solid rgba($color: #C8C8C8, $alpha: 0.7);
        border-top: 0px;

        .sidebarHeader {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                bottom: 0px;
                left: 50%;
                transform: translateX(-50%);
                height: 1px;
                width: 90%;
                background-color: #C8C8C8;
            }

            // img{
            //     width: 100%;
            //     height: auto;
            //     object-fit: contain;
            // }
            // border-bottom: 1px solid #C8C8C8;
        }

        .pro-sidebar-inner {
            background-color: #fff;

            .pro-sidebar-layout {

                // common decalre
                .pro-menu {
                    .pro-menu-item {
                        
                        .pro-inner-item {
                            padding: 8px 5px 8px 15px;
                            .pro-icon-wrapper {
                                //position: relative;

                                &::after {
                                    content: "";
                                    position: absolute;
                                    display: block;
                                    top: 50%;
                                    left: -6px;
                                    width: 4px;
                                    height: 4px;
                                    border-radius: 50%;
                                    transform: translateY(-50%);
                                    background-color: #0344a5;
                                    transition: .2s;
                                    opacity: 0;
                                }

                                .pro-icon {
                                    svg {
                                        width: 21px;
                                        height: auto;

                                        path {
                                            fill: #000000;
                                        }
                                    }
                                }
                            }
                            
                            &:hover {
                                background-color: rgba($color: #000000, $alpha: 0.07);
                            }

                            &::before {
                                box-shadow: none;
                            }
                        }

                        &.active,
                        &.firstLevelMenu.open {
                            background-color: #d8d8d8;
                            .pro-inner-item {
                                // background-color: rgba($color: #000000, $alpha: 0.07);
                                .pro-icon-wrapper {
                                    &::after {
                                        // width: 70%;
                                        opacity: 1;
                                    }
                                }

                                .pro-icon {
                                    svg {
                                        path {
                                            fill: #0344a5;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .pro-menu-item.open  {
                        background-color: #d8d8d8;  
                    }
                    .pro-menu-item.pro-sub-menu {
                        .pro-inner-list-item {
                            background-color: #fcfcfc;                             
                        }
                    }
                    &.submenu-bullets {
                        .pro-menu-item {
                            .pro-menu-item {
                                .pro-inner-item {
                                    &::before {
                                        box-shadow: none;
                                        width: 6px;
                                        max-width: 6px;
                                        height: 6px;
                                        background-color: #C4C4C4 !important;
                                    }
                                }

                                &.open {
                                    .pro-inner-item {
                                        &::before {
                                            background-color: #0344a5 !important;
                                        }
                                    }
                                }

                                &.proMenuItems {
                                    .pro-inner-item {
                                        &::before {
                                            box-shadow: none;
                                            background-color: #C4C4C4 !important;
                                        }
                                    }

                                    &.active {
                                        .pro-inner-item {
                                            &::before {
                                                background-color: #0344a5 !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                // Header
                .pro-sidebar-header {
                    border-bottom: none;

                    .pro-menu {
                        padding-bottom: 0;
                    }
                }

                // body
                .pro-sidebar-content {
                    .pro-menu {
                        padding-bottom: 0;

                        .pro-menu-item {
                            .react-slidedown {
                                margin-top: -5px;
                                padding-left: 15px;
                            }

                            .pro-item-content {
                                color: #000000;
                                font-weight: 400;
                            }

                            &.active {
                                .pro-item-content {
                                    font-weight: 800;
                                    font-size: 13px;
                                    color: #083a7b;
                                }
                            }
                        }
                    }
                }

                // footer
                .pro-sidebar-footer {
                    position: relative;
                    border: none;
        
                    div {
                        background-color: #042c5c;
                        color: #fff;
                        font-size: 11px;
                        font-weight: 800;
                   }  
                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        background-color: #C8C8C8;
                        width: 80%;
                        height: .5px;
                    }

                    .pro-item-content {
                        span {
                            color: #fff;
                            font-weight: 600;
                        }
                    }

                    .sidebarFooterFlex {
                        position: relative;
                        display: flex;
                        // padding: 20px 35px;
                        justify-content: space-between;
                        transform: translateX(0%);
                        transition: transform 0.3s;
                        scrollbar-width: none;

                        &::after {
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: 43%;
                            transform: translateY(-50%);
                            height: 40%;
                            width: 1px;
                            background-color: #042c5c;
                        }
                        .footerIcons {
                            display: flex;
                            justify-content: center;
                            
                        }

                        .icon {
                            width: 18px; 
                            height: 18px; 
                            // margin-top: 0.5em;
                        }
                        .sidebarFooterHelp,
                        .sidebarFooterVersion {
                            padding: 0 10px;
                            
                            span {
                                color: #0344a5;
                                font-weight: 600;
                            }  
                        }

                        div {
                            a {
                                font-size: 12px;
                                color: #000000;

                                &:hover,
                                &:active {
                                    color: #000000;
                                }
                            }

                            .d-flex {
                                .sidebarFooterTitle {
                                    padding-left: 10px;
                                }

                                &.active {
                                    .sidebarFooterIcon {
                                        position: relative;

                                        &::after {
                                            content: "";
                                            position: absolute;
                                            bottom: -8px;
                                            left: -3px;
                                            width: 20px;
                                            height: 2px;
                                            background-color: #0344a5;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.collapsed {
                        &::before {
                            height: 0;
                        }

                        .sidebarFooterFlex {
                            transform: translateX(-100%);
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

// Mobile responsive

@media(max-width: 1060px) {
    .hamburger {
        display: block;
    }

    // Layout
    .mainApp {
        .sidebarAside {
            width: 0px;

            .sidebar {
                transform: translateX(-200%);
            }
        }

        .mainPages {
            width: calc(100% - 0px);
        }

        &.collapsed {
            .sidebarAside {
                max-width: 0px;
                min-width: 0px;
            }
        }

        &.menuActive {
            .sidebarAside {
                .sidebar {
                    transform: translateX(0%);
                }
            }
        }
    }
}