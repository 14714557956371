// linksGroup
.linksGroup{
    .linksCount{
        height: 10px;
        width: 10px;
        background-color: #545454;
        color: #fff;
        border-radius: 50%;
        display: grid;
        place-items: center;
    }
}

.clean {
    height: 25px;
    width: 50px;
    background-color: #bcb8b8;
    color: #fff;
    font-size: 12px;
    // border-radius: 50%;
  }

.imageSlider{
    width: 800px;
    height: auto;
    margin: 0 auto;
}

.containerCard {
    position: relative;
    padding-left: 1em;
}

.backgroundImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 79vw;
    height: 150vh;
    opacity: 0.1;
    //padding: 2px;
}

.sideImage {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    
}
.image-card {
  width: 12px; 
  height: auto; 
  padding-top: 2px;
  margin-top: -5px; 
}
.ant-table {
    .ant-table-container {
        .ant-table-content {
            table {
                thead.ant-table-thead {
                    .ant-table-cell { 
                        // background-color: #26548f; 
                        background-color: #707a8a;
                        color: white;
                        font-size: 11px;
                        
                    }   
                }

                tbody.ant-table-tbody {
                    .ant-table-cell { 
                        // background-color: #26548f; 
                        background-color: #d0cfcfe3;
                        
                    }   
                }

            }
        }
    }        
}

.ant-tabs-card {
    >.ant-tabs-nav {
        .ant-tabs-tab {
            background-color: #e4e7ea;
        }
    }
}
.inner-border {
	border: 2px solid steelblue;
	padding: 1rem;
	margin: .25rem;
   
}

.outer-border {
	border: 10px groove #26548f;
	padding: 1rem;
	margin: .25rem;

}

.header-subtitle-text {  
    // font-weight: 700;
    color:#0344a5;
    // margin-bottom:10px;
    text-align: center;
    h6 {
        font-size: 15px;
    }
    h4 {
        font-size: 23px;
    }
  }
.content {
    font-size: 12px;
}
.notes {
    font-size: 10px;
    color: gray;
}
.content-title{
    font-size:13px;
    font-weight: 700;
}
.ant-select-single .ant-select-selector {
    font-size: 13px;
}
  
// .ant-table .ant-table-container .ant-table-content table thead.ant-table-thead .ant-table-cell { 
//     background-color: #d8d7dab3; 
    
//   }

//   .ant-tabs-card >.ant-tabs-nav .ant-tabs-tab {
//     background-color: #e4e7ea;
//     /* color: #C8C8C8; */
//   }
.highlighted {
    border: 1px solid #64a4fc;
    border-radius: 5px;
}
.dropdowns {
    width: 81%
}
.disabled-select .ant-select-selector {
    background-color: rgb(212, 25, 25); /* Set the background color for the disabled state */
    cursor: not-allowed;
}
.centerImage {
    height:100%;
    width: 100%;
    margin-top:30px;
    // display: block;
    // margin-left: auto;
    // margin-right: auto;
    margin-bottom: 30px;   
}
.likesCount {
    color: #0e3aea;
    font-size: 10px;
    padding-left: 2px;
    padding-top: 15px;
    font-weight: 800;
    margin-bottom: 15px;
}

.full-backgroundImage {
    // height: 100%;
    width: 100%;
}
.parentContainer {
    position: relative;
    
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 5px;
    a,
    a:hover {
        text-decoration: none;
        color: rgb(4, 123, 251);
    }
    a:hover::before {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      }

    a {
        text-decoration: none;
        color: white;
    }
}
.shake-anchor {
    transition: transform 0.1s ease-in-out;
    display: inline-block;
    
    font-weight: 800;
}
.shake-anchor:hover {
    animation: shake 0.5s ease-in-out;
}
@keyframes shake{
    0%{transform: translateX(0);}
    20%{transform: translateX(-5px) rotate(5deg);}
    40%{transform: translateX(5px) rotate(-5deg);}
    60%{transform: translateX(-5px) rotate(5deg);}
    80%{transform: translateX(5px) rotate(-5deg);}
    100%{transform: translateX(0)}
}
.card-height {
    height: 45px;
}
.tab-header {
    font-size: 18px;
	font-weight: bold;
	font-family: "Open Sans", Helvetica, Arial, sans-serif;
	font-weight: 600;
    text-align: center;
    margin-top: 5px;
}
.ant-tabs-nav {
    display: flex;
    width: 100%;
  }
  
  .ant-tabs-nav-list {
    flex: 1;
    display: flex;
  }
  
  .ant-tabs-nav .ant-tabs-tab {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0; /* Default background color for non-active tabs */
  }
  
  .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
    background-color: #083a7b; /* Change background color of active tab */
  }
  
  .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-btn {
    color: #090909; /* Change text color of all tabs */
  }
  .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff; /* Change text color of active tab */
  }
  a,
a:hover {
    text-decoration: none;
    color: #083a7b;
    font-weight: 700;
}
.flow-text {
    color: #083a7b;
    font-size: 15px;
}
.dataGridContainer {
    padding-top: 10px; 
    border: 5px solid transparent; 
}
.backgroundImageContainer {
    position: relative;
    display: inline-block; 
  }
  
  .backgroundImage1 {
    width: 100%;
    height: auto; 
    opacity: 0.1;
  }
  
  .excelIcon {
    position: absolute;
    transform: translate(70%, 20%);
  }
  .textContainer {
    position: absolute;
    font-weight: 700;
    bottom: -11rem; 
    left: 10%; 
    transform: translateX(-40%);
  }
  .iconHeader {
     // margin-top: 95px;
    margin-left: 80px;
    font-size: 13px;
    font-weight: 1000;
    color: #083a7b;

  }