@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
}

a,
a:hover {
  text-decoration: none;
  color: #6946C6;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

button,
select,
input {
  outline: none !important;
  white-space: nowrap;
}

label,
ul {
  margin: 0;
}

.p-text {
  font-size: 16px;
  font-weight: 500;
}
.p-text strong {
  font-weight: 600;
}
.p-text span {
  color: #6946C6;
}

.h1-text {
  font-size: 35px;
  line-height: 45px;
  font-weight: 800;
}
.h1-text span {
  color: #6946C6;
}

.title-text {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  text-align: center;
}
.title-text span {
  color: #6946C6;
}

.subtitle-text {
  font-size: 30px;
  line-height: 30px;
  font-weight: 400;
  margin-top: -25px;
}
.subtitle-text span {
  color: #6946C6;
}

.cursor-pointer {
  cursor: pointer !important;
}

.header-menu-links div {
  color: #FFFFFF;
  font-weight: 600;
  margin-top: -20px;
  font-size: 30px;
}

.page-header {
  font-size: 25px;
  font-weight: bold;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 600;
  text-align: center;
  margin-bottom: 15px;
}

@media (max-width: 600px) {
  .p-text {
    font-size: 15px;
  }
  .h1-text {
    font-size: 22px;
    line-height: 25px;
  }
  .title-text {
    font-size: 35px;
    line-height: 40px;
  }
  .subtitle-text {
    font-size: 22px;
    line-height: 25px;
  }
}
.container {
  max-width: 1400px;
  padding-left: 5px;
  padding-right: 5px;
}

@media (max-width: 600px) {
  .container {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .navBarHead .navBar {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
}
.main-header {
  position: relative;
}
.main-header .placeHolderPlaceOfHeader {
  height: 60px;
}

.navbar {
  position: fixed;
  top: 0;
  right: 0;
  background: #083a7b;
  color: #fff;
  border: 0 solid #000000;
  top: 0;
  font-size: 13px;
  z-index: 1011;
  height: 60px;
  width: 100%;
  border-bottom: 1px solid rgba(200, 200, 200, 0.7);
  box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.25);
}

.header-menu-links div {
  color: #000;
  font-weight: 500;
  margin-top: -25px;
  font-size: 18px;
}

.input-large div {
  width: 450px;
}

.hamburgerLink {
  padding-left: 0.5rem;
}

@media (max-width: 1060px) {
  .hamburgerLink {
    padding-left: 1.5rem;
  }
}
.user-container {
  position: relative;
  cursor: pointer;
  margin-top: 2px;
}

.user-dropdown {
  display: none;
  position: absolute;
  top: calc(100% + 0px); /* Adjust the spacing as needed */
  right: 0;
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #ccc;
  padding: 4px;
  margin-top: 8px;
  height: 180px;
  width: 310px;
}

.user-dropdown,
.user-dropdown:hover {
  display: block;
}

.profileDisplay {
  border-radius: 50%;
  height: 45px;
  width: 45px;
  font-size: 15px;
  padding: 12px;
  color: #083a7b;
  background-color: #d7d7db;
  font-weight: 600;
  margin-top: -6px;
}

.large {
  height: 80px;
  width: 80px;
  padding: 19.5px;
  font-weight: 500;
  font-size: 30px;
  margin: 0px 15px 0px 8px;
}

.user-name {
  font-size: 14px;
  font-weight: 600;
  margin-top: 2px;
}

.user-email {
  font-size: 11px;
}

.signout {
  border: 0px;
  background: transparent;
  font-size: 12px;
}

.signin {
  border-radius: 50%;
  height: 45px;
  width: 45px;
  margin-top: -6px;
  background-color: #d7d7db;
}

.mega-menu {
  margin-top: 25px;
}

a {
  color: #0078d6;
  background-color: transparent;
  text-decoration: underline;
}

li {
  margin-top: 0.3em;
}

.custom-button {
  background-color: #083a7b;
  color: #fff;
}

.mainApp .sidebarAside {
  position: relative;
  width: 280px;
  float: left;
  transition: width, left, right, 0.3s;
}
.mainApp .sidebarAside .sidebar {
  position: fixed;
  left: 0;
  bottom: 0px;
  width: 280px;
  height: calc(100vh - 60px);
  color: #fff;
  transition: width, left, right, 0.3s;
  z-index: 1009;
}
.mainApp .mainPages {
  position: relative;
  width: calc(100% - 280px);
  transition: width, left, right, 0.3s;
  float: right;
}
.mainApp.collapsed .sidebarAside {
  width: 80px;
}
.mainApp.collapsed .sidebarAside .sidebar {
  width: 80px;
}
.mainApp.collapsed .sidebarAside .sidebar .sidebarCollapsed svg {
  transform: rotate(180deg);
}
.mainApp.collapsed .mainPages {
  width: calc(100% - 80px);
}

.hamburger {
  position: fixed;
  top: 15px;
  left: 10px;
  z-index: 1012;
  display: none;
  background-color: transparent;
  border: none;
}
.hamburger svg {
  width: 20px;
  height: auto;
}

.icon-footer {
  margin-left: 25px;
}

.sidebar .sidebarCollapsed {
  position: absolute;
  top: 50%;
  right: -15px;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-color: #878787;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.5);
  z-index: 1010;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: transform 0.3s;
}
.sidebar .sidebarCollapsed svg {
  width: 13px;
  height: auto;
}
.sidebar .pro-sidebar {
  width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  border: 1px solid rgba(200, 200, 200, 0.7);
  border-top: 0px;
}
.sidebar .pro-sidebar .sidebarHeader {
  position: relative;
}
.sidebar .pro-sidebar .sidebarHeader::after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  height: 1px;
  width: 90%;
  background-color: #C8C8C8;
}
.sidebar .pro-sidebar .pro-sidebar-inner {
  background-color: #fff;
}
.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-menu .pro-menu-item .pro-inner-item {
  padding: 8px 5px 8px 15px;
}
.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-menu .pro-menu-item .pro-inner-item .pro-icon-wrapper::after {
  content: "";
  position: absolute;
  display: block;
  top: 50%;
  left: -6px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  transform: translateY(-50%);
  background-color: #0344a5;
  transition: 0.2s;
  opacity: 0;
}
.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-menu .pro-menu-item .pro-inner-item .pro-icon-wrapper .pro-icon svg {
  width: 21px;
  height: auto;
}
.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-menu .pro-menu-item .pro-inner-item .pro-icon-wrapper .pro-icon svg path {
  fill: #000000;
}
.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-menu .pro-menu-item .pro-inner-item:hover {
  background-color: rgba(0, 0, 0, 0.07);
}
.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-menu .pro-menu-item .pro-inner-item::before {
  box-shadow: none;
}
.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-menu .pro-menu-item.active, .sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-menu .pro-menu-item.firstLevelMenu.open {
  background-color: #d8d8d8;
}
.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-menu .pro-menu-item.active .pro-inner-item .pro-icon-wrapper::after, .sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-menu .pro-menu-item.firstLevelMenu.open .pro-inner-item .pro-icon-wrapper::after {
  opacity: 1;
}
.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-menu .pro-menu-item.active .pro-inner-item .pro-icon svg path, .sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-menu .pro-menu-item.firstLevelMenu.open .pro-inner-item .pro-icon svg path {
  fill: #0344a5;
}
.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-menu .pro-menu-item.open {
  background-color: #d8d8d8;
}
.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  background-color: #fcfcfc;
}
.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-menu.submenu-bullets .pro-menu-item .pro-menu-item .pro-inner-item::before {
  box-shadow: none;
  width: 6px;
  max-width: 6px;
  height: 6px;
  background-color: #C4C4C4 !important;
}
.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-menu.submenu-bullets .pro-menu-item .pro-menu-item.open .pro-inner-item::before {
  background-color: #0344a5 !important;
}
.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-menu.submenu-bullets .pro-menu-item .pro-menu-item.proMenuItems .pro-inner-item::before {
  box-shadow: none;
  background-color: #C4C4C4 !important;
}
.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-menu.submenu-bullets .pro-menu-item .pro-menu-item.proMenuItems.active .pro-inner-item::before {
  background-color: #0344a5 !important;
}
.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-header {
  border-bottom: none;
}
.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-header .pro-menu {
  padding-bottom: 0;
}
.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-content .pro-menu {
  padding-bottom: 0;
}
.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-content .pro-menu .pro-menu-item .react-slidedown {
  margin-top: -5px;
  padding-left: 15px;
}
.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-content .pro-menu .pro-menu-item .pro-item-content {
  color: #000000;
  font-weight: 400;
}
.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-content .pro-menu .pro-menu-item.active .pro-item-content {
  font-weight: 800;
  font-size: 13px;
  color: #083a7b;
}
.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer {
  position: relative;
  border: none;
}
.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer div {
  background-color: #042c5c;
  color: #fff;
  font-size: 11px;
  font-weight: 800;
}
.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #C8C8C8;
  width: 80%;
  height: 0.5px;
}
.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer .pro-item-content span {
  color: #fff;
  font-weight: 600;
}
.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer .sidebarFooterFlex {
  position: relative;
  display: flex;
  justify-content: space-between;
  transform: translateX(0%);
  transition: transform 0.3s;
  scrollbar-width: none;
}
.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer .sidebarFooterFlex::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 43%;
  transform: translateY(-50%);
  height: 40%;
  width: 1px;
  background-color: #042c5c;
}
.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer .sidebarFooterFlex .footerIcons {
  display: flex;
  justify-content: center;
}
.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer .sidebarFooterFlex .icon {
  width: 18px;
  height: 18px;
}
.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer .sidebarFooterFlex .sidebarFooterHelp,
.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer .sidebarFooterFlex .sidebarFooterVersion {
  padding: 0 10px;
}
.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer .sidebarFooterFlex .sidebarFooterHelp span,
.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer .sidebarFooterFlex .sidebarFooterVersion span {
  color: #0344a5;
  font-weight: 600;
}
.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer .sidebarFooterFlex div a {
  font-size: 12px;
  color: #000000;
}
.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer .sidebarFooterFlex div a:hover, .sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer .sidebarFooterFlex div a:active {
  color: #000000;
}
.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer .sidebarFooterFlex div .d-flex .sidebarFooterTitle {
  padding-left: 10px;
}
.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer .sidebarFooterFlex div .d-flex.active .sidebarFooterIcon {
  position: relative;
}
.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer .sidebarFooterFlex div .d-flex.active .sidebarFooterIcon::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: -3px;
  width: 20px;
  height: 2px;
  background-color: #0344a5;
}
.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer.collapsed::before {
  height: 0;
}
.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer.collapsed .sidebarFooterFlex {
  transform: translateX(-100%);
  display: none;
}

@media (max-width: 1060px) {
  .hamburger {
    display: block;
  }
  .mainApp .sidebarAside {
    width: 0px;
  }
  .mainApp .sidebarAside .sidebar {
    transform: translateX(-200%);
  }
  .mainApp .mainPages {
    width: calc(100% - 0px);
  }
  .mainApp.collapsed .sidebarAside {
    max-width: 0px;
    min-width: 0px;
  }
  .mainApp.menuActive .sidebarAside .sidebar {
    transform: translateX(0%);
  }
}
.linksGroup .linksCount {
  height: 10px;
  width: 10px;
  background-color: #545454;
  color: #fff;
  border-radius: 50%;
  display: grid;
  place-items: center;
}

.clean {
  height: 25px;
  width: 50px;
  background-color: #bcb8b8;
  color: #fff;
  font-size: 12px;
}

.imageSlider {
  width: 800px;
  height: auto;
  margin: 0 auto;
}

.containerCard {
  position: relative;
  padding-left: 1em;
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 79vw;
  height: 150vh;
  opacity: 0.1;
}

.sideImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}

.image-card {
  width: 12px;
  height: auto;
  padding-top: 2px;
  margin-top: -5px;
}

.ant-table .ant-table-container .ant-table-content table thead.ant-table-thead .ant-table-cell {
  background-color: #707a8a;
  color: white;
  font-size: 11px;
}
.ant-table .ant-table-container .ant-table-content table tbody.ant-table-tbody .ant-table-cell {
  background-color: rgba(208, 207, 207, 0.8901960784);
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  background-color: #e4e7ea;
}

.inner-border {
  border: 2px solid steelblue;
  padding: 1rem;
  margin: 0.25rem;
}

.outer-border {
  border: 10px groove #26548f;
  padding: 1rem;
  margin: 0.25rem;
}

.header-subtitle-text {
  color: #0344a5;
  text-align: center;
}
.header-subtitle-text h6 {
  font-size: 15px;
}
.header-subtitle-text h4 {
  font-size: 23px;
}

.content {
  font-size: 12px;
}

.notes {
  font-size: 10px;
  color: gray;
}

.content-title {
  font-size: 13px;
  font-weight: 700;
}

.ant-select-single .ant-select-selector {
  font-size: 13px;
}

.highlighted {
  border: 1px solid #64a4fc;
  border-radius: 5px;
}

.dropdowns {
  width: 81%;
}

.disabled-select .ant-select-selector {
  background-color: rgb(212, 25, 25); /* Set the background color for the disabled state */
  cursor: not-allowed;
}

.centerImage {
  height: 100%;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
}

.likesCount {
  color: #0e3aea;
  font-size: 10px;
  padding-left: 2px;
  padding-top: 15px;
  font-weight: 800;
  margin-bottom: 15px;
}

.full-backgroundImage {
  width: 100%;
}

.parentContainer {
  position: relative;
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 5px;
}
.parentContainer a,
.parentContainer a:hover {
  text-decoration: none;
  color: rgb(4, 123, 251);
}
.parentContainer a:hover::before {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}
.parentContainer a {
  text-decoration: none;
  color: white;
}

.shake-anchor {
  transition: transform 0.1s ease-in-out;
  display: inline-block;
  font-weight: 800;
}

.shake-anchor:hover {
  animation: shake 0.5s ease-in-out;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(-5px) rotate(5deg);
  }
  40% {
    transform: translateX(5px) rotate(-5deg);
  }
  60% {
    transform: translateX(-5px) rotate(5deg);
  }
  80% {
    transform: translateX(5px) rotate(-5deg);
  }
  100% {
    transform: translateX(0);
  }
}
.card-height {
  height: 45px;
}

.tab-header {
  font-size: 18px;
  font-weight: bold;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 600;
  text-align: center;
  margin-top: 5px;
}

.ant-tabs-nav {
  display: flex;
  width: 100%;
}

.ant-tabs-nav-list {
  flex: 1;
  display: flex;
}

.ant-tabs-nav .ant-tabs-tab {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0; /* Default background color for non-active tabs */
}

.ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
  background-color: #083a7b; /* Change background color of active tab */
}

.ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-btn {
  color: #090909; /* Change text color of all tabs */
}

.ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff; /* Change text color of active tab */
}

a,
a:hover {
  text-decoration: none;
  color: #083a7b;
  font-weight: 700;
}

.flow-text {
  color: #083a7b;
  font-size: 15px;
}

.dataGridContainer {
  padding-top: 10px;
  border: 5px solid transparent;
}

.backgroundImageContainer {
  position: relative;
  display: inline-block;
}

.backgroundImage1 {
  width: 100%;
  height: auto;
  opacity: 0.1;
}

.excelIcon {
  position: absolute;
  transform: translate(70%, 20%);
}

.textContainer {
  position: absolute;
  font-weight: 700;
  bottom: -11rem;
  left: 10%;
  transform: translateX(-40%);
}

.iconHeader {
  margin-left: 80px;
  font-size: 13px;
  font-weight: 1000;
  color: #083a7b;
}/*# sourceMappingURL=style.css.map */