.main-header {
    position: relative;

    .placeHolderPlaceOfHeader {
        height: 60px;
    }
}

.navbar {
    position: fixed;
    top: 0;
    right: 0;
    background: #083a7b;
    color: #fff;
    border: 0 solid #000000;
    top: 0;
    font-size: 13px;
    z-index: 1011;
    height: 60px;
    width: 100%;
    border-bottom: 1px solid rgba($color: #C8C8C8, $alpha: 0.7);
    box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.25);
}

.header-menu-links {
    div {
        color: #000;
        font-weight: 500;
        margin-top: -25px;
        font-size: 18px;
    }
}

.input-large {
   div {
     width: 450px;
  }
}

.hamburgerLink {
    padding-left: 0.5rem;
}

@media (max-width: 1060px) {
    .hamburgerLink {
        padding-left: 1.5rem;
    }
}  

.user-container {
    position: relative;
    cursor: pointer;
    margin-top: 2px;
  }
  
.user-dropdown {
    display: none;
    position: absolute;
    top: calc(100% + 0px); /* Adjust the spacing as needed */
    right: 0;
    background-color: #ffffff;
    color: #000000;
    border: 1px solid #ccc;
    padding: 4px;
    margin-top: 8px;
    height: 180px;
    width: 310px;
  }
  
  .user-dropdown,
  .user-dropdown:hover {
    display: block;
  }

.profileDisplay {
    // border: 1px solid #fff;
    border-radius: 50%;
    height: 45px;
    width: 45px;
    font-size: 15px;
    padding: 12px;
    color: #083a7b;
    background-color: #d7d7db;
    font-weight: 600;
    margin-top: -6px;
}
.large{
    height: 80px;
    width: 80px;
    padding: 19.5px;
    font-weight: 500;
    font-size: 30px;
    margin: 0px 15px 0px 8px;
}

.user-name {
    font-size: 14px;
    font-weight: 600;
    margin-top: 2px;
    // overflow: hidden;
    // white-space: nowrap;
    // text-overflow: ellipsis;
    // position: relative;
    //  width: 200px;
}
.user-email {
    font-size: 11px;
}
// .ellipsis:hover::before {
//     content: attr(data-fullname);
//     background-color: rgba(255, 255, 255, 0.9); /* Background color on hover */
//   position: absolute;
//   top: 100%;
//   left: 0;
//   white-space: normal;
//   padding: 5px;
//   border: 1px solid #ccc; /* Border on hover */
//   border-radius: 5px;
//   z-index: 1;
// }

.signout {
    border:0px;
    background: transparent;
    font-size: 12px;
}

.signin {
    // border: 1px solid #fff;
    border-radius: 50%;
    height: 45px;
    width: 45px;
    margin-top: -6px;
    background-color: #d7d7db;

}
.mega-menu {
    margin-top: 25px;
}

a {
    color: #0078d6;
    background-color: transparent;
    text-decoration: underline;
}

li {
    margin-top: 0.3em;
}
.custom-button {
    background-color: #083a7b;
    color: #fff;
}